import React, {Component} from "react";
import Button from "components/CustomButton/CustomButton.jsx";
import Card from "../Card/Card";
import Papa from "papaparse";

class FileUploader extends Component {
    constructor(props) {
        super(props);
        this.object = this.props.prototype;
        this.state = {
            items: this.props.value,
            uploaded_file: null,
            selectedFile: null
        };
        this.uploadAcceptType='.csv'
    }

    prepareFileData = () => {
        this.state.uploaded_file.map(row => {
            Object.keys(row).map((key) => {
                let value = row[key]
                if (typeof (value) == "string" && value.startsWith("[")) {
                    row[key] = JSON.parse(row[key])
                }
            })
        })
    }

    preparePayload = () => {
        if (this.state.selectedFile) {
            this.prepareFileData()
            return this.state.uploaded_file
        } else {
            alert("First you need to choose a file!");
        }
    };

    updateItems = items => {
        let payload = this.preparePayload()
        this.setState({items: payload}, () => {
            this.props.onChange(this.state.items, this.props.name)
        });
    }

    fileUploadHandler = event => {
        const items = Object.assign([], this.state.items);
        let newItem = {}
        items.push(newItem)
        this.updateItems(items)
    };

    reloadDefaults(component) {
        this.getDefaultValues(component).then(
            value => {
                this.setState({defaults: value})
            }
        )
    }

    fileSelectedHandler = event => {
        this.state.selectedFile = event.target.files[0]
        Papa.parse(this.state.selectedFile, {
            header: true,
            dynamicTyping: true,
            skipEmptyLines: true,
            worker: true,
            complete: (results) => {
                this.state.uploaded_file = results.data
            }
        }, () => this.reloadDefaults(this.props.name))
    }

    render() {
        return (
            <Card
                    content={
                        <div className="clearfix">
                            <div className="float-container"
                                 style={{height: 100, float: "left"}}>
                                <input type="file" accept={this.props.uploadAcceptType}
                                       onChange={this.fileSelectedHandler}
                                       className="form-group"/>
                                <Button block bsStyle="success"
                                        onClick={(e) => this.fileUploadHandler(e)}>
                                    Add
                                </Button>
                            </div>
                        </div>
                    }/>
        )
    }
}

export default FileUploader;
