import BaseTranslationComponent from "../../Generic/BaseTranslationComponent";

export default class GenderBodyPartTranslation extends BaseTranslationComponent {

    constructor(props) {
        super(props);

        this.name = "Gender Body Part Translations"
        this.addPath = "/admin/translations/gender-body-parts/add";
        this.editPath = "/admin/translations/gender-body-parts/edit";
        this.removeUrl = "/admin/translations/gender-body-parts";
        this.listApiUrl = "/admin/translations/gender-body-parts";
        this.instructionUrl="https://welltech.atlassian.net/wiki/spaces/MYC/pages/4008378436/Body+Part+Translation";
    }
}
