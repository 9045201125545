import {WorkoutEditModel} from "../../models/Workout";
import WorkoutAdd from "./WorkoutAdd";
import React from "react";

export default class WorkoutEdit extends WorkoutAdd {
    constructor(props) {
        super(props);
        this.skipPrepopulation = true
    }
    render() {
        return React.cloneElement(
            super.render(),
            {
                md: 12,
                responseData: this.locationState().workout
            }
        )
    }
    getEntity = () => {
        return WorkoutEditModel(this.context.constants);
    }
}