import BaseAddEditComponent from "../Generic/BaseAddEditComponent";
import {ApiConstantsContext} from "../../variables/ApiConstantsContext";
import {GenderBodyPart} from "../../models/GenderBodyPart";

export default class GenderBodyPartAdd extends BaseAddEditComponent {
    static contextType = ApiConstantsContext;
    constructor(props) {
        super(props);
        this.name = "GenderBodyPart"
        this.url = "/admin/gender-body-part"

    }
    getEntity = () => {
        return GenderBodyPart(this.context.constants);
    }
}
