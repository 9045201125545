import BaseAddEditComponent from "../Generic/BaseAddEditComponent";
import {GenderWorkoutTypeModel} from "../../models/WorkoutType";

export default class GenderWorkoutTypeAdd extends BaseAddEditComponent {
    constructor(props) {
        super(props);
        this.name = "GenderWorkoutTypes"
        this.url = "/admin/gender-workout-types"

    }

    getEntity = () => {
        return GenderWorkoutTypeModel(this.context.constants);
    }

}