import React, {Component} from "react";
import List from "../../components/List/List";
import {NavLink} from "react-router-dom";
import Button from "react-bootstrap/lib/Button";
import API from "@aws-amplify/api";
import {ApiConstantsContext} from "../../variables/ApiConstantsContext";
import {constList} from "../../utils/Utils";
import defaults from "../../defaults";
import {BranchWorkoutTypeFilter} from "../../models/BranchWorkoutType";

let g = defaults.userGroups

export default class BranchWorkoutType extends Component{
    static contextType = ApiConstantsContext;

    constructor(props){
        super(props);
        this.addPath = "/admin/branch-workout-type/add";
        this.editPath = "/admin/branch-workout-type/edit";
        this.state = {
            key: 0
        }

    }
    setBranchStatus = (event, goal_id, status) => {
        let button = event.target;
        button.disable = true;
        let url = `/admin/branch-workout-type/${goal_id}/status`

        API.put('admin', url, {'body': {"is_published": status}})
            .then(data=> {
                this.props.handleClick(`Successfully set status to ${status}`, "success", "tr");
                this.setState({key: Math.random()});
            }).catch(error=>{
                this.props.handleClick("Failed to set status: " + JSON.stringify(error.response.data), "error", "tr");
        }).finally(() => {button.disable = false;})
    };
    render(){
        let constants = this.context.constants;
        let c = this.context.get_const;
        return (
            <List
                key={this.state.key}
                name="BranchWorkoutType"
                actions={[
                    (list, data) => {
                        return <NavLink
                            to={{
                                pathname: list.props.editPath + '/' + data.id,
                                state: {
                                    training_goal: data,
                                }
                            }}
                            className="btn btn-primary btn-fill"
                            >Edit</NavLink>
                    },
                    (list, data) => {
                        if (data.status === "soft_deleted") {
                            return undefined;
                        }
                        return <Button
                            allowedGroups={[g.admin, g.editor]}
                            className="btn btn-danger btn-fill"
                            onClick={(e) => list.handleRemove(data.id, e)}>
                            Delete
                        </Button>
                    },
                    (list, data) => {
                        if (data.status === "published") {
                            return <Button
                                className="btn btn-warning btn-fill"
                                onClick={(e) => this.setBranchStatus(e, data.id, false)}
                            >
                                Unpublish
                            </Button>
                        } else {
                            return <Button
                                className="btn btn-info btn-fill"
                                onClick={(e) => this.setBranchStatus(e, data.id, true)}
                            >
                                Publish
                            </Button>
                        }
                    }
                ]}
                addPath={this.addPath}
                editPath={this.editPath}
                listApiUrl="/admin/branch-workout-type"
                removeUrl="/admin/branch-workout-type"
                model={[
                    {col: "ID", row: "id"},
                    {col: "Branch Name", row: "branch_name"},
                    {col: 'Gender', row: 'gender'},
                    {
                        col: "Workout Types",
                        row: "workout_types",
                        normalizer: (data) => {
                            if (data !== undefined){
                                return constList(c, data, 'workout_types', 'workout_type')
                            }
                        }
                    },
                ]}
                handleClick={this.props.handleClick}
                filterObject={BranchWorkoutTypeFilter}
                userHasPermission={this.props.userHasPermission}
            >
            </List>
        );
    }

}