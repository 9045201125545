export const UserFilter = {
    ids: {
        type: 'input',
        value: '',
        placeholder: 'Ids separated by comma',
        requestNormalizer: (data) => {
            return JSON.stringify(Array.from(data.matchAll(/\d+/g), m => parseInt(m[0])));
        }
    },
    email: {
        type: 'input',
        value: '',
    }
};

export const UserTokenFilter = {
    user_id: {
        type: 'input',
        inputType: 'number',
        value: '',
        placeholder: 'User id'
    },
    email: {
        type: 'input',
        value: '',
        placeholder: 'User email'
    }
}