import BaseAddEditComponent from "../Generic/BaseAddEditComponent";
import debounce from "debounce-promise";
import {API} from "aws-amplify";
import {constToSelectOptions} from "../../utils/Utils";
import React from "react";
import {BranchWorkoutTypeModel} from "../../models/BranchWorkoutType";

export default class BranchWorkoutTypeAdd extends BaseAddEditComponent{
    constructor(props) {
        super(props);
        this.name="BranchWorkoutType"
        this.url="/admin/branch-workout-type"
        this.debouncedLoadOptions = debounce(this.loadGoals, 2000);

    }

    detectSearchParameter = input => {
        input = input.toLowerCase()
        let enums = [
            "name"
        ]
        if (!isNaN(input)) {
            return ["ids", JSON.stringify([parseFloat(input)])]
        }
        for (const enumKey of enums) {
            for (let [key, value] of Object.entries(constToSelectOptions(this.context.constants, enumKey))) {
                if (value.toLowerCase().includes(input)) {

                    return [enumKey, key]
                }
            }
        }
        return ["name", input]
    }

    loadGoals = input => {
        let queryParams = {
            "per_page": "100",
            "page":"1"
        }

        if (input.length > 0) {
            let [searchParam, searchValue] = this.detectSearchParameter(input)
            queryParams[searchParam] = searchValue
        }
        return API.get('admin', "/admin/training-goals", {
            'queryStringParameters': queryParams
        })
            .then(data => {
                let  workouts = data.items.reduce((obj, item) => {
                    item.gender = this.context.get_const("gender", item.gender);
                    obj[item.id] = item;
                    return obj;
                }, {});
                return Object.values(workouts);
            }).catch(error => {
                console.error(error)
                return []
            })
    }
    getEntity = () => {
        return BranchWorkoutTypeModel(
            this.context.constants,
        )
    }

    render() {
        return React.cloneElement(
            super.render(),
            {md: 12}
        )
    }
}