export const WorkoutTypeTranslationModel = (workout_type, language, name, description) => {
    return {
        workout_type: {
            value: workout_type,
            hidden: true
        },
        language: {
            value: language,
            hidden: true
        },
        name: {
            type: 'input',
            validationRules: 'required',
            value: name,
        },
        description: {
            type: 'input',
            value: description,
        },
    };
}
