import BaseAddEditComponent from "../../Generic/BaseAddEditComponent";
import {WorkoutTranslationModel} from "../../../models/WorkoutTranslation";


export default class WorkoutTranslationAdd extends BaseAddEditComponent {
    constructor(props) {
        super(props);
        this.name = "Workout Translation"
        this.url = "/admin/translations/workouts"
        this.skipPrepopulation = true
    }

    locationState = () => {
        return this.props.location.state || {language: {}, translation: {}}
    }

    getTitle = () => {
        return `${this.isEdit() ? "Edit" : "Add"} ${this.name} for ${this.locationState().language.title}`
    }

    getEntity = () => {
        let state = this.locationState()
        let translation = state.translation || {}

        return WorkoutTranslationModel(
            state.resource || null,
            state.language.key || null,
            translation.name || null,
            translation.audio_url || null,
            translation.instruction || null
        );
    }
}
