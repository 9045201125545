export const EquipmentGroupTranslationModel = (equipment_group_id, language, name, description) => {
    return {
        equipment_group_id: {
            value: equipment_group_id,
            hidden: true
        },
        language: {
            value: language,
            hidden: true
        },
        name: {
            type: 'input',
            validationRules: 'required',
            value: name,
        },
        description: {
            type: 'input',
            value: description,
        },
    };
}