export const TrainingGoalsTranslationModel  = (training_goal_id, language, name, description) => {
    return {
        training_goal_id: {
            value: training_goal_id,
            hidden: true
        },
        language: {
            value: language,
            hidden: true
        },
        name: {
            type: 'input',
            validationRules: 'required',
            value: name,
        },
        description: {
            type: 'input',
            validationRules: '',
            value: description,
        }
    };
}