import React, {Component} from "react";
import {ApiConstantsContext} from "../../variables/ApiConstantsContext";
import List from "../../components/List/List";
import {NavLink} from "react-router-dom";


export default class BaseTranslationComponent extends Component {
    static contextType = ApiConstantsContext;

    constructor(props) {
        super(props);
        this.addPath = "";
        this.editPath = "";
        this.removeUrl = "";
        this.listApiUrl = "";
        this.name = "";
        this.instructionUrl="";
        this.updateApiUrl="/admin/translation/exercise/update";
        this.emailReport=undefined;
    }

    generateAddEditLink = (translation, resource, language) => {
        if (translation !== undefined) {
            return <NavLink
                to={{
                    pathname: this.editPath + '/' + translation.id,
                    state: {
                        resource: resource,
                        language: language,
                        translation: translation
                    }
                }}
            >
                {translation.name}
            </NavLink>
        }
        return <NavLink
            to={{
                pathname: this.addPath,
                state: {resource: resource, language: language}
            }}
            style={{color: 'red'}}
        >
            Add
        </NavLink>
    }

    generateModel = () => {
        let constants = this.context.constants;
        let languages = Object.values(constants.language === undefined ? {} : constants.language.items || {});
        languages.sort(
            (a, b) => a.value - b.value
        );
        let active_languages = languages.filter(language => language['published'] === true);
        let model = [{col: 'Id', row: 'id'}];
        for (const lang of active_languages) {
            model.push({
                col: lang.title,
                row: "languages",
                normalizer: (data) => {
                    return this.generateAddEditLink(data.translations[lang.key], data.id, lang)
                }
            });
        }
        return model;
    }

    generateFilter = () => {
        return undefined
    }
    render() {
        return (
            <List
                name={this.name}
                disableActions
                editPath={this.editPath}
                removeUrl={this.removeUrl}
                listApiUrl={this.listApiUrl}
                model={this.generateModel()}
                onRemove={this.onRemove}
                uploadAcceptType='.csv'
                instructionUrl={this.instructionUrl}
                handleClick={this.props.handleClick}
                filterObject={this.generateFilter()}
                userHasPermission={this.props.userHasPermission}
                updateApiUrl={this.updateApiUrl}
            >
            </List>
        );
    }
}