import {buildSelector, constToSelectOptions} from "../utils/Utils";

export const GenderBodyPartGroup = constants => {
    return {
        body_part_group: buildSelector(constToSelectOptions(constants, 'body_part_group'), 'select'),
        preview: {
            type: 'input',
            validationRules: ['required', {'regex': "^https://mobile.appscdn.io/.*"}],
        },
        gender: buildSelector(constToSelectOptions(constants, 'gender'), 'select'),
    };
}
