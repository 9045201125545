import React, {Component} from "react";
import {Col, Row} from "react-bootstrap";
import Button from "components/CustomButton/CustomButton.jsx";
import FormRow from "./FormRow";
import API from "@aws-amplify/api";
import {CheckPath} from "../../utils/Utils";


class FilterForm extends Component {
    constructor(props) {
        super(props);
        let filterDefaults = {};
        Object.keys(this.props.object).map((key) => {
            return filterDefaults[key] = this.props.object[key].value
        });
        this.state = filterDefaults;
    }

    handleClick = (event) => {
        this.props.onSubmit(this.prepareRequestData())
    };

    handleSubmit = event => {
        event.preventDefault();
        this.props.onSubmit(this.prepareRequestData())
    };
    handleInput = event => {
        this.setState({[event.target.name]: event.target.value});
    };
    handleSelect = (value, event) => {
        this.setState({[event.name]: value});
    };
    prepareRequestData = () => {
        let requestData = {};

        Object.keys(this.props.object).map((key) => {
            if (this.state[key] && this.state[key] !== null && !this.props.object[key].hidden) {
                return requestData[key] = this.props.object[key].requestNormalizer === undefined
                    ? this.state[key]
                    : this.props.object[key].requestNormalizer(this.state[key])
            }

            return null
        });

        return requestData
    };
    setRecalculation = (event) => {
        API.post('admin', '/admin/recalculate-workouts', {'body': {"need_recalculate": true}})
            .then(data => {
                this.handleClick(event);
            }).catch(error => {
                this.handleClick(event);
            }
        );
    }

    render() {
        return (
            <Row>
                <form onSubmit={this.handleSubmit}>
                    {Object.keys(this.props.object).map((key, index) => {
                        if (!this.props.object[key].hidden) {
                            return <Col md={2} key={index}>
                                <FormRow
                                    name={key}
                                    type={this.props.object[key].type || 'input'}
                                    value={this.state[key]}
                                    onChangeEvent={this[this.props.object[key].onChangeEvent] || this.handleInput}
                                    selectOptions={this.props.object[key].selectOptions || null}
                                    label={false}
                                    placeholder={this.props.object[key].placeholder || key}
                                    selectProps={this.props.object[key].selectProps}
                                />
                            </Col>
                        }
                        return null
                    })}
                    <div className="clearfix"/>
                    <div className="container" style={{height: 50, width: 225, float: "right"}}>
                        <Button bsStyle="primary" type="submit"
                                style={{margin: '12px 0'}}
                                onClick={(e) => this.handleSubmit(e)}>
                            Search
                        </Button>
                        {CheckPath(<Button bsStyle="danger" type="submit"
                                style={{margin: '12px 0'}}
                                onClick={(e) => this.setRecalculation(e)}>
                            Recalculate
                        </Button>)}
                    </div>
                </form>
            </Row>
        );
    }
}

export default FilterForm;
