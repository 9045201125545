import BaseAddEditComponent from "../Generic/BaseAddEditComponent";
import {ExerciseModel} from "../../models/Exercise";
import debounce from "debounce-promise";
import {constToSelectOptions} from "../../utils/Utils";
import {API} from "aws-amplify";


export default class ExerciseAdd extends BaseAddEditComponent {
    constructor(props) {
        super(props);
        this.state = {
            workouts: {},
        }
        this.name = "Exercise"
        this.url = "/admin/exercises"
        this.redirectUrl = "/admin/exercises"
        this.debouncedLoadOptions = debounce(this.loadExercises, 2000);
    }
    detectSearchParameter = input => {
        input = input.toLowerCase()
        let enums = [
            "body_part",
            "equipment",
        ]
        if (!isNaN(input)) {
            return ["ids", JSON.stringify([parseFloat(input)])]
        }
        for (const enumKey of enums) {
            for (let [key, value] of Object.entries(constToSelectOptions(this.context.constants, enumKey))) {
                if (value.toLowerCase().includes(input)) {
                    if (enumKey === "tags") {
                        key = JSON.stringify([key])
                    }
                    return [enumKey, key]
                }
            }
        }
        return ["name", input]
    }

    loadExercises = input => {
        let queryParams = {
            "per_page": "100",
            "page":"1"
        }

        if (input.length > 0) {
            let [searchParam, searchValue] = this.detectSearchParameter(input)
            queryParams[searchParam] = searchValue
        }
        return API.get('admin', "/admin/exercises", {
            'queryStringParameters': queryParams
        })
            .then(data => {
                let exercises = data.items.reduce((obj, item) => {
                    obj[item.id] = item;
                    return obj;
                }, {});
                return Object.values(exercises);
            }).catch(error => {
                return []
            })
    }

    getEntity = () => {
        return ExerciseModel(
            this.context.constants,
            inputValue => this.debouncedLoadOptions(inputValue)
        );
    }
}
