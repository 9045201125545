import BaseTranslationComponent from "../../Generic/BaseTranslationComponent";

export default class WorkoutTypeTranslation extends BaseTranslationComponent {
    constructor(props) {
        super(props);
        this.name = "Workout Type Translations"
        this.addPath = "/admin/translations/workout-types/add";
        this.editPath = "/admin/translations/workout-types/edit";
        this.removeUrl = "/admin/translations/workout-types";
        this.listApiUrl = "/admin/translations/workout-types";
        this.instructionUrl="https://welltech.atlassian.net/wiki/spaces/MYC/pages/4008542818/Workout+Type+Translation";
    }
}
