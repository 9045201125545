import GenderBodyPartGroupAdd from "./GenderBodyPartGroupAdd";
import React from "react";

export default class GenderBodyPartGroupEdit extends GenderBodyPartGroupAdd {
    constructor(props) {
        super(props);
        this.skipPrepopulation = true
    }

    render() {
        return React.cloneElement(
            super.render(),
            {
                md: 12,
                responseData: this.locationState().gender_body_part_group
            }
        )
    }
}
