import React, {Component} from "react";
import List from "../../components/List/List";
import {ApiConstantsContext} from "../../variables/ApiConstantsContext";
import {NavLink} from "react-router-dom";
import Button from "react-bootstrap/lib/Button";
import API from "@aws-amplify/api";
import {GenderBodyPartFilter} from "../../models/GenderBodyPart";


export default class GenderBodyPart extends Component {
    static contextType = ApiConstantsContext;

    constructor(props) {
        super(props);
        this.addPath = '/admin/gender-body-part/add';
        this.editPath = '/admin/gender-body-part/edit';
        this.state = {
            key: 0
        }
    }

    setBodyPartStatus = (event, body_part_id, status) => {
        let button = event.target;
        button.disable = true;
        let url = `/admin/gender-body-part/${body_part_id}/status`

        API.put('admin', url, {'body': {"is_published": status}})
            .then(data=> {
                this.props.handleClick(`Successfully set status to ${status}`, "success", "tr");
                this.setState({key: Math.random()});
            }).catch(error=>{
                this.props.handleClick("Failed to set status: " + JSON.stringify(error.response.data), "error", "tr");
        }).finally(() => {button.disable = false;})
    };
    render() {
        let constants = this.context.constants;
        return (
            <List
                key={this.state.key}
                name="GenderBodyPart"
                addPath={this.addPath}
                editPath={this.editPath}
                actions={[
                    (list, data) => {
                        return <NavLink
                            to={{
                                pathname: list.props.editPath + '/' + data.id,
                                state: {
                                    body_part: data,
                                }
                            }}
                            className="btn btn-primary btn-fill"
                            >Edit</NavLink>
                    },
                    (list, data) => {
                        if (data.is_published) {
                            return <Button
                                className="btn btn-warning btn-fill"
                                onClick={(e) => this.setBodyPartStatus(e, data.id, false)}
                            >
                                Unpublish
                            </Button>
                        } else {
                            return <Button
                                className="btn btn-info btn-fill"
                                onClick={(e) => this.setBodyPartStatus(e, data.id, true)}
                            >
                                Publish
                            </Button>
                        }
                    }
                ]}
                listApiUrl='/admin/gender-body-part'
                model={[
                    {col: 'Id', row: 'id'},
                    {col: 'Name', row: 'name'},
                    {col: 'Body Part', row: 'body_part'},
                    {col: 'Gender', row: 'gender'},
                    {
                        col: 'Preview', row: 'preview', normalizer: (data) => {
                            return <a href={data.preview} target={"blank"}>
                                <img alt={data.name}
                                     src={data.preview}
                                     width="150"/>
                            </a>
                        }
                    },
                    {col: 'Position', row: 'position'},

                    ]}
                onRemove={this.onRemove}
                handleClick={this.props.handleClick}
                filterObject={GenderBodyPartFilter(constants)}
                userHasPermission={this.props.userHasPermission}
            >
            </List>

        );
    }
}
