import {WorkoutModel} from "../../models/Workout";
import BaseAddEditComponent from "../Generic/BaseAddEditComponent";

export default class WorkoutAdd extends BaseAddEditComponent {
    constructor(props) {
        super(props);
        this.name = "Workout"
        this.url = "/admin/workouts"
    }

    getEntity = () => {
        return WorkoutModel(this.context.constants);
    }
}