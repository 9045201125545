export const BodyPartTranslationModel = (body_part, language, name) => {
    return {
        body_part: {
            value: body_part,
            hidden: true
        },
        language: {
            value: language,
            hidden: true
        },
        name: {
            type: 'input',
            validationRules: 'required',
            value: name,
        }
    };
}

export const GenderBodyPartTranslationModel = (gender_body_part_id, language, name, description) => {
    return {
        gender_body_part_id: {
            value: gender_body_part_id,
            hidden: true
        },
        language: {
            value: language,
            hidden: true
        },
        name: {
            type: 'input',
            validationRules: 'required',
            value: name,
        },
        description: {
            type: 'input',
            validationRules: '',
            value: description,
        }
    };
}