import BaseAddEditComponent from "../../Generic/BaseAddEditComponent";
import {EquipmentGroupTranslationModel} from "../../../models/EquipmentGroupTranslation";


export default class EquipmentGroupTranslationAdd extends BaseAddEditComponent {
    constructor(props) {
        super(props);
        this.name = "EquipmentGroup Translation"
        this.url = "/admin/translations/equipment-groups"
        this.skipPrepopulation = true
    }

    locationState = () => {
        return this.props.location.state || {language: {}, translation: {}}
    }

    getTitle = () => {
        return `${this.isEdit() ? "Edit" : "Add"} ${this.name} for ${this.locationState().language.title}`
    }

    getEntity = () => {
        let state = this.locationState()
        let translation = state.translation || {}

        return EquipmentGroupTranslationModel(
            state.resource || null,
            state.language.key || null,
            translation.name || null,
            translation.description || null
        );
    }
}
