import React, {Component} from "react";
import {Col, Grid, Row, Table} from "react-bootstrap";
import Card from "../components/Card/Card";

import {ApiConstantsContext} from "../variables/ApiConstantsContext";

export default class Info extends Component {
    static contextType = ApiConstantsContext;

    constructor(props) {
        super(props);
        this.tableKeys = ['value', 'key', 'title']

    }

    buildTable = (title, items) => {
        if (title === "Equipment Groups")
            this.tableKeys.push("equipment")
        const content = <Table striped hover>
                            <thead>
                            <tr>
                                {this.tableKeys.map((key, index) => {
                                    return <th
                                        key={index}>{key}</th>;
                                })}
                            </tr>
                            </thead>
                            <tbody>
                            {
                                Object.keys(items).map((key, index) => {
                                    return <tr key={index}>
                                        {this.tableKeys.map((itemKey, j) => {
                                            return <td
                                                        key={j}>
                                                        {
                                                            items[key][itemKey]
                                                        }
                                                   </td>;
                                            }
                                            )
                                        }
                                    </tr>
                                })
                            }
                            </tbody>
                    </Table>
        this.tableKeys = this.tableKeys.filter(e => e !== "equipment")
        return <Card
                    title= {title}
                    ctTableFullWidth
                    ctTableResponsive
                    content={content}
                    />
        }

render()
{
    let constants = this.context.constants;
    return (
        <div className="content">
            <Grid fluid>
                <Row md={12}>
                    {Object.keys(constants).map((key, j) => {
                        return <Col md={12} key={j}>
                            {
                                this.buildTable(constants[key]["title"], constants[key]["items"])
                            }
                        </Col>;
                    })}
                </Row>
            </Grid>

        </div>
    );
}
}

