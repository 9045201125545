import BaseTranslationComponent from "../../Generic/BaseTranslationComponent";

export default class EquipmentTranslation extends BaseTranslationComponent {
    constructor(props) {
        super(props);
        this.name = "Equipment Translations"
        this.addPath = "/admin/translations/equipments/add";
        this.editPath = "/admin/translations/equipments/edit";
        this.removeUrl = "/admin/translations/equipments";
        this.listApiUrl = "/admin/translations/equipments";
        this.instructionUrl="https://welltech.atlassian.net/wiki/spaces/MYC/pages/4007198772/Equipment+Translation";
    }
}