import BaseTranslationComponent from "../../Generic/BaseTranslationComponent";

export default class GenderEquipmentTranslation extends BaseTranslationComponent {
    constructor(props) {
        super(props);
        this.name = "Gender Equipment Translations"
        this.addPath = "/admin/translations/gender-equipments/add";
        this.editPath = "/admin/translations/gender-equipments/edit";
        this.removeUrl = "/admin/translations/gender-equipments";
        this.listApiUrl = "/admin/translations/gender-equipments";
        this.instructionUrl="https://welltech.atlassian.net/wiki/spaces/MYC/pages/4007198772/Equipment+Translation";
    }
}