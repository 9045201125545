import {buildSelector, constToSelectOptions, yesNoSelector} from "../utils/Utils";

export const GenderBodyPart = constants => {
    return {
        name: {
            type: 'input',
            validationRules: 'required',
            value: '',
        },
        body_part: buildSelector(constToSelectOptions(constants, 'body_part'), 'select'),
        preview: {
            type: 'input',
            value:'',
            validationRules: [{'regex': "^https://mobile.appscdn.io/.*"}],
        },
        gender: buildSelector(constToSelectOptions(constants, 'gender'), 'select'),

        description: {
            type: 'input',
            value: '',
        },
        position: {
            validationRules: '',
            requestNormalizer: (data) => {
                return parseInt(data);
            }
        },
        is_published: yesNoSelector()
    };
}

export const GenderBodyPartFilter = constants => {
    return {
        id: {
            type: 'input',
            value: '',
            placeholder: 'Id'
        },
        name: {
            type: 'input',
            value: '',
            placeholder: 'name'
        },
        gender: buildSelector(constToSelectOptions(constants, 'gender')),
    }
};