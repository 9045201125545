import {buildSelector, constToSelectOptions, yesNoSelector} from "../utils/Utils";
import {API} from "aws-amplify";
import {components} from "react-select";
import React from "react";

const Option = ({children, ...props}) => {
    let option = props.data

    return (
        <components.Option {...props}>
            <div className="clearfix">
                <div className="mb-3 pull-left">
                    <img alt={"Preview"} className="pull-left mr-2 img-rounded"
                         width="228"
                         height="128"
                         src={option.preview}/>
                </div>
                <div className="mb-9 pull-left" style={{"marginLeft": "10px"}}>
                    <p style={{"margin": "0"}}><strong>{`[${option.id}] ${option.name}`}</strong>
                    </p>
                    <span style={{"margin": "0"}}>
                        Gender: <strong>{option.gender}</strong>
                    </span><br/>
                </div>
            </div>
        </components.Option>
    );
};

export const GoalWorkoutModel = (constants, loadOptions) => {
    let objCache = {}
    return {
        workout_id: {
            type: 'select',
            validationRules: 'required',
            onChangeEvent: 'handleSelect',
            value: '',
            requestNormalizer: (option) => {
                return option.id
            },
            responseNormalizer: (option) => {
                let workout_id = option
                if (objCache[workout_id] !== undefined) {
                    return objCache[workout_id]
                }
                return API.get('admin', `/admin/workouts/${workout_id}`)
                    .then(data => {
                        objCache[workout_id] = data;
                        return data;
                    }).catch(error => {
                        console.error(error)
                        return {}
                    })
            },
            selectProps: {
                getOptionLabel: option => {
                    return `[${option['id']}] ${option['name']}`
                },
                getOptionValue: option => {
                    return option['id']
                },
                pageSize: 10,
                components: {Option},
                minMenuHeight: 300,
                maxMenuHeight: 500,
                loadOptions: loadOptions,
            },
            md: 5
        },
    };
}

export const TrainingGoals = (constants, loadWorkouts) => {
    return {
        name: {type: 'input', validationRules: 'required',value: ''},
        preview: {
            type: 'input',
            validationRules: ['', {'regex': "^https://mobile.appscdn.io/.*"}],
            value: '',
        },
        gender: buildSelector(constToSelectOptions(constants, 'gender'), 'select'),
        required_workout_type: buildSelector(constToSelectOptions(constants, 'workout_type'), 'multiSelect'),
        recommended_workout_type: {
            ...buildSelector(constToSelectOptions(constants, 'workout_type'), 'multiSelect'),
            validationRules: '',
            value: []

        },
        position: {
            validationRules: '',
            requestNormalizer: (data) => {
                return parseInt(data);
            }
        },
        is_default: yesNoSelector(),
        training_program: {
            type: 'table_collection',
            value: [],
            prototype: GoalWorkoutModel(constants, loadWorkouts),
            onChangeEvent: 'handleCollection',
            validationRules: '',
        },
        __validator__: (component, object) => {
            let gender = object.gender.value
            for (let workout of object.training_program) {
                if (typeof workout.workout_id !== "string" && !window.location.pathname.includes("/admin/training-goals/edit")) {
                    let workout_gender = workout.workout_id.gender
                    if (gender !== workout_gender) {
                        alert(
                            `You can't add this workout because this goal for ${gender} and workout for ${workout_gender}!`
                        )
                        workout.workout_id = ""
                    }
                }
            }
        }

    }
}


export const TrainingGoalsFilter = constants => {
    return {
        training_goal_id: {
            type: 'input',
            value: '',
            placeholder: 'Id'
        },
        name: {
            type: 'input',
            value: '',
            placeholder: 'name'
        },
        gender: buildSelector(constToSelectOptions(constants, 'gender')),
        required_workout_type: buildSelector(constToSelectOptions(constants, 'workout_type')),
        recommended_workout_type: buildSelector(constToSelectOptions(constants, 'workout_type'))
    }
};