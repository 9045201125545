import GenderEquipmentGroupAdd from "./GenderBodyPartGroupAdd";
import React from "react";

export default class GenderEquipmentGroupEdit extends GenderEquipmentGroupAdd {
    constructor(props) {
        super(props);
        this.skipPrepopulation = true
    }

    render() {
        return React.cloneElement(
            super.render(),
            {
                md: 12,
                responseData: this.locationState().gender_equipment_group
            }
        )
    }
}
