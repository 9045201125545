export const BodyPartGroupTranslationModel = (body_part_group_id, language, name, description) => {
    return {
        body_part_group_id: {
            value: body_part_group_id,
            hidden: true
        },
        language: {
            value: language,
            hidden: true
        },
        name: {
            type: 'input',
            validationRules: 'required',
            value: name,
        },
        description: {
            type: 'input',
            value: description,
        },
    };
}