import BaseAddEditComponent from "../Generic/BaseAddEditComponent";
import {GenderEquipmentGroup} from "../../models/GenderEquipmentGroup";
import {ApiConstantsContext} from "../../variables/ApiConstantsContext";

export default class GenderEquipmentGroupAdd extends BaseAddEditComponent {
    static contextType = ApiConstantsContext;
    constructor(props) {
        super(props);
        this.name = "GenderEquipmentGroup"
        this.url = "/admin/gender-equipment-groups"

    }
    getEntity = () => {
        return GenderEquipmentGroup(this.context.constants);
    }
}
