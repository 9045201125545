import {buildSelector, constToSelectOptions} from "../utils/Utils";

export const LinksCheckerModel = constants => {
    return {
        table: buildSelector(
            constToSelectOptions(constants, 'tables'),
            'select',
            (data) => {
                return data.value;
            },
        ),
        emails: {
            type: 'input',
            value: '',
            validationRules: 'required',
            placeholder: 'Emails separated by comma',
            requestNormalizer: (data) => {
                return JSON.stringify(data.split(','));
            }
        },
        language: buildSelector(
            constToSelectOptions(constants, 'language'),
            'select'
        ),
        timedelta_days: {
            type: 'input',
            inputType: 'number',
            value: 0,
            requestNormalizer: (data) => {
                return parseInt(data);
            }
        },
        need_check: {
            value: true,
            hidden: true,
        }
    }
};