import React from "react";
import BaseAddEditComponent from "../Generic/BaseAddEditComponent";
import {TrainingGoalsSettingsEditModel} from "../../models/TrainingGoalsSettings";

export default class TrainingGoalsSettingsEdit extends BaseAddEditComponent{
    constructor(props) {
        super(props);
        this.name="TrainingGoalsSettings"
        this.url="/admin/goal-additional-settings"
    }
    getEntity = () => {
        return TrainingGoalsSettingsEditModel(this.context.constants)
    }
}