import BaseTranslationComponent from "../../Generic/BaseTranslationComponent";

export default class BodyPartGroupTranslation extends BaseTranslationComponent {
    constructor(props) {
        super(props);
        this.name = "Body Part Group Translations"
        this.addPath = "/admin/translations/body-part-groups/add";
        this.editPath = "/admin/translations/body-part-groups/edit";
        this.removeUrl = "/admin/translations/body-part-groups";
        this.listApiUrl = "/admin/translations/body-part-groups";
        this.instructionUrl="https://welltech.atlassian.net/wiki/spaces/MYC/pages/4008542811/Body+Part+Group+Translation";
    }
}