import BaseTranslationComponent from "../../Generic/BaseTranslationComponent";

export default class TrainingGoalsTranslations extends BaseTranslationComponent{
        constructor(props) {
        super(props);
        this.name = "Training Goals"
        this.addPath = "/admin/translations/training-goals/add";
        this.editPath = "/admin/translations/training-goals/edit";
        this.removeUrl = "/admin/translations/training-goals";
        this.listApiUrl = "/admin/translations/training-goals";
    }
}


