import React from "react";
import BaseAddEditComponent from "../Generic/BaseAddEditComponent";
import {ContentUploadModel} from "../../models/ContentUpload";

class ContentUpload extends BaseAddEditComponent {
    constructor(props) {
        super(props);
        this.name = "Content Upload"
        this.url = "/admin/csv-upload"
        this.redirectUrl = "/admin/content-upload"
    }
    getEntity = () => {
        return ContentUploadModel(
            this.context.constants,
        );
    }
    render() {
        return React.cloneElement(
            super.render(),
            {md: 12}
        )
    }
}

export default ContentUpload;