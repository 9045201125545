import React, {Component} from "react";
import List from "../../components/List/List";
import {ApiConstantsContext} from "../../variables/ApiConstantsContext";
import defaults from "../../defaults";
import {NavLink} from "react-router-dom";

let g = defaults.userGroups


export default class GenderBodyPartGroup extends Component {
    static contextType = ApiConstantsContext;

    constructor(props) {
        super(props);
        this.addPath = '/admin/gender-body-part-groups/add';
        this.editPath = '/admin/gender-body-part-groups/edit';
        this.state = {
            key: 0
        }
    }


    render() {
        return (
            <List
                key={this.state.key}
                name="GenderBodyPartGroups"
                addPath={this.addPath}
                editPath={this.editPath}
                actions={[
                    (list, data) => {
                    return <NavLink
                            allowedGroups={[g.admin, g.editor, g.viewer]}
                            to={{
                                pathname: list.props.editPath + '/' + data.id,
                                state: {
                                    gender_body_part_group: data,
                                }
                            }}
                            className="btn btn-primary btn-fill"
                        >
                            Edit
                        </NavLink>
                    },
                ]}
                listApiUrl='/admin/gender-body-part-groups'
                model={[
                    {col: 'Id', row: 'id'},
                    {
                        col: 'Body Part Group',
                        row: 'body_part_group',
                    },
                    {
                        col: 'Gender',
                        row: 'gender',
                    },
                    {
                        col: 'Preview', row: 'preview', normalizer: (data) => {
                            return <a href={data.preview} target={"blank"}>
                                <img alt={data.name}
                                     src={data.preview}
                                     width="150"/>
                            </a>
                        }
                    }
                    ]}
                onRemove={this.onRemove}
                handleClick={this.props.handleClick}
                userHasPermission={this.props.userHasPermission}
            >
            </List>

        );
    }
}
