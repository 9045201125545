import GenderBodyPartAdd from "./GenderBodyPartAdd";
import React from "react";

export default class GenderBodyPartEdit extends GenderBodyPartAdd {
    constructor(props) {
        super(props);
        this.skipPrepopulation = true
    }

    render() {
        return React.cloneElement(
            super.render(),
            {
                md: 12,
                responseData: this.locationState().body_part
            }
        )
    }
}
