import {buildSelector, constToSelectOptions} from "../utils/Utils";
import React from "react";


export const BranchWorkoutTypeModel = constants => {
    return {
        branch_name: {
            type: 'input',
            validationRules: 'required',
            value: '',
        },
        gender: buildSelector(constToSelectOptions(constants, 'workout_gender'), 'select'),
        workout_types: {
            ...buildSelector(constToSelectOptions(constants, 'workout_type'), 'multiSelect'),
            validationRules: '',
            value: []
        }
    }
}


export const BranchWorkoutTypeFilter = {
    ids: {
            type: 'input',
            value: '',
            placeholder: 'Id'
        },
    branch_name: {
            type: 'input',
            validationRules: 'required',
            value: '',
        }
};

export const BranchWorkoutTypeEditModel = constants => {
    return {
        branch_name: {
            type: 'input',
            value: '',
            placeholder: 'Id'
        },
        gender: buildSelector(constToSelectOptions(constants, 'workout_gender'), 'select'),
        workout_types: {
            ...buildSelector(constToSelectOptions(constants, 'workout_type'), 'multiSelect'),
            validationRules: '',
            value: []
        }

    }
};