import {buildSelector, constToSelectOptions} from "../utils/Utils";

export const GenderEquipmentGroup = constants => {
    return {
        equipment_group: buildSelector(constToSelectOptions(constants, 'equipment_groups'), 'select'),
        preview: {
            type: 'input',
            validationRules: ['required', {'regex': "^https://mobile.appscdn.io/.*"}],
        },
        gender: buildSelector(constToSelectOptions(constants, 'gender'), 'select'),
    };
}
