import BaseAddEditComponent from "../../Generic/BaseAddEditComponent";
import {WorkoutTypeTranslationModel} from "../../../models/WorkoutTypeTranslation";


export default class WorkoutTypeTranslationAdd extends BaseAddEditComponent {
    constructor(props) {
        super(props);
        this.name = "WorkoutType Translation"
        this.url = "/admin/translations/workout-types"
        this.skipPrepopulation = true
    }

    locationState = () => {
        return this.props.location.state || {language: {}, translation: {}}
    }

    getTitle = () => {
        return `${this.isEdit() ? "Edit" : "Add"} ${this.name} for ${this.locationState().language.title}`
    }

    getEntity = () => {
        let state = this.locationState()
        let translation = state.translation || {}

        return WorkoutTypeTranslationModel(
            state.resource || null,
            state.language.key || null,
            translation.name || null,
            translation.description || null
        );
    }
}
