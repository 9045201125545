import {buildSelector, constToSelectOptions} from "../utils/Utils";

export const WorkoutModel = constants => {
    return {
        name: {
            type: 'input',
            validationRules: 'required',
            value: '',
        },
        preview: {
            type: 'input',
            validationRules: ['required', {'regex': "^https://mobile.appscdn.io/.*"}],
            value: '',
        },
        workout_type: buildSelector(constToSelectOptions(constants, 'workout_type')),
        fitness_level: buildSelector(constToSelectOptions(constants, 'fitness_level')),
        gender: buildSelector(constToSelectOptions(constants, 'workout_gender')),
    }
};

export const WorkoutEditModel = constants => {
    return {
        name: {
            type: 'input',
            validationRules: 'required',
            value: '',
        },
        preview: {
            type: 'input',
            validationRules: ['required', {'regex': "^https://mobile.appscdn.io/.*"}],
            value: '',
        },
        fitness_level: buildSelector(constToSelectOptions(constants, 'fitness_level')),
        gender: buildSelector(constToSelectOptions(constants, 'workout_gender')),
    }
};

export const WorkoutFilter = constants => {
    return {
        ids: {
            type: 'input',
            value: '',
            placeholder: 'Ids',
            requestNormalizer: (data) => {
                return JSON.stringify(Array.from(data.matchAll(/\d+/g), m => parseInt(m[0])));
            }
        },
        workout_name: {
            type: 'input',
            value: '',
        },
        workout_types: buildSelector(
            constToSelectOptions(constants, 'workout_type'),
            'multiSelect',
            (data) => {
                return JSON.stringify(data.map(a => a.value));
            },
            undefined,
            4
        ),
        status: buildSelector(
            constToSelectOptions(constants, 'workout_status'),
            'select'
        ),
        genders: buildSelector(
            constToSelectOptions(constants, 'workout_gender'),
            'multiSelect',
            (data) => {
                return JSON.stringify(data.map(a => a.value));
            }
        ),
        fitness_levels: buildSelector(
            constToSelectOptions(constants, 'fitness_level'),
            'multiSelect',
            (data) => {
                return JSON.stringify(data.map(a => a.value));
            }
        ),
        target_body_part_groups: buildSelector(
            constToSelectOptions(constants, 'body_part_group'),
            'multiSelect',
            (data) => {
                return JSON.stringify(data.map(a => a.value));
            }
        ),
        equipment_groups: buildSelector(
            constToSelectOptions(constants, 'equipment_groups'),
            'multiSelect',
            (data) => {
                return JSON.stringify(data.map(a => a.value));
            }
        ),
    }
};
