import BaseAddEditComponent from "../Generic/BaseAddEditComponent";
import {GenderBodyPartGroup} from "../../models/GenderBodyPartGroup";
import {ApiConstantsContext} from "../../variables/ApiConstantsContext";

export default class GenderBodyPartGroupAdd extends BaseAddEditComponent {
    static contextType = ApiConstantsContext;
    constructor(props) {
        super(props);
        this.name = "GenderBodyPartGroups"
        this.url = "/admin/gender-body-part-groups"

    }
    getEntity = () => {
        return GenderBodyPartGroup(this.context.constants);
    }
}
