import {buildSelector, constToSelectOptions} from "../utils/Utils";

export const AudioFilter = constants => {
    return {
        ids: {
            type: 'input',
            value: '',
            placeholder: 'Ids',
            requestNormalizer: (data) => {
                return JSON.stringify(Array.from(data.matchAll(/\d+/g), m => parseInt(m[0])));
            }
        },
        text: {
            type: 'input',
            value: '',
        },

        workout_type: buildSelector(
            constToSelectOptions(constants, 'workout_type'),
            'select'
        ),
        language: buildSelector(
            constToSelectOptions(constants, 'audio_language'),
            'select'
        ),
        audio_type: buildSelector(
            constToSelectOptions(constants, 'audio_type'),
            'select'
        ),
    }
};

export const AudioModel = constants => {
    return {
        audio_type: buildSelector(constToSelectOptions(constants, 'audio_type')),
        text: {
            type: 'input',
            validationRules: 'required',
            value: '',
        },

        value: {
            inputType: 'number',
            validationRules: '',
            value: '',
            requestNormalizer: (data) => {
                return parseFloat(data);
            }
        },

        url: {
            type: 'input',
            validationRules: 'required',
            value: '',
        },

        language: buildSelector(constToSelectOptions(constants, 'audio_language')),

        workout_type: {...buildSelector(constToSelectOptions(constants, 'workout_type'),'multiSelect'),
            validationRules: '',
            value: [],
        }

    }
};
