import {buildSelector, constToSelectOptions, generateBadgeRow} from "../utils/Utils";
import {components} from "react-select";
import {NavLink} from "react-router-dom";
import React from "react";


const Option = ({children, ...props}) => {
    let option = props.data
    return (
        <components.Option {...props}>
            <div className="clearfix">
                <div className="mb-9 pull-left" style={{"marginLeft": "10px"}}>
                    <p style={{"margin": "0"}}>
                        <NavLink
                            to={{
                                pathname: "/admin/training-goals/edit/" + option.id,
                            }}
                            target="_blank"
                            style={{color: 'black'}}
                        >
                            <strong>[{option.id}] {option.name}</strong> <span
                            className="glyphicon glyphicon-new-window"/>
                        </NavLink>
                    </p>
                    <span style={{"margin": "0"}}>
                        Gender: <strong>{option.gender}</strong>
                    </span><br/>
                </div>
            </div>
        </components.Option>
    );
};


export const TrainingGoalsSettingsModel = (constants, loadGoals) => {
    let objCache = {}
    return {
        training_goal_id: {
            type: 'select',
            validationRules: 'required',
            onChangeEvent: 'handleSelect',
            value: '',
            requestNormalizer: function (data) {
                return parseInt(data.id);
            },
            responseNormalizer: (option) => {
                let goal_id = option
                if (objCache[goal_id] !== undefined) {
                    return objCache[goal_id]
                }
                return {}

            },
            selectProps: {
                components: {Option},
                getOptionLabel: option => {
                    return `[${option['id']}] ${option['internal_name']}`
                },
                getOptionValue: option => {
                    return option['id']
                },
                pageSize: 10,
                minMenuHeight: 300,
                maxMenuHeight: 1000,
                loadOptions: loadGoals,
            },
            md: 5
        },
        required_tasks: {
            ...buildSelector(constToSelectOptions(constants, 'plan_task'), 'multiSelect'),
            validationRules: '',
            value: []
        }
    }
}


export const TrainingGoalsSettingsFilter = constants => {
    return {
        training_goal_id: {
            type: 'input',
            value: '',
            placeholder: 'Id'
        },

    }
};

export const TrainingGoalsSettingsEditModel = constants => {
    return {
        training_goal_id: {
            type: 'input',
            value: '',
            placeholder: 'Id'
        },
        required_tasks: {
            ...buildSelector(constToSelectOptions(constants, 'plan_task'), 'multiSelect'),
            validationRules: '',
            value: []
        }

    }
};