import {buildSelector, constToSelectOptions, generateBadgeRow, yesNoSelector} from "../utils/Utils";
import {components} from "react-select";
import React from "react";
import {API} from "aws-amplify";

export const ExerciseKeys = [
    'body_part',
    'equipment',
    'exercise_level',
    'workout_block',
    'bmi_level',
    'age_group',
];

const Option = ({children, ...props}) => {
    let option = props.data

    if (option === null){
        return null
    }
    return (
        <components.Option {...props}>
            <div className="clearfix">
                <div className="mb-3 pull-left">
                    <img alt={"Preview"} className="pull-left mr-2 img-rounded"
                         width="128"
                         height="128"
                         src={option.preview}/>
                </div>
                <div className="mb-9 pull-left" style={{"marginLeft": "10px"}}>
                    <p style={{"margin": "0"}}><strong>{`[${option.id}] ${option.name}`}</strong>
                    </p>
                    {generateBadgeRow("Body parts", option.body_parts)}<br/>
                    {generateBadgeRow("Equipment", option.equipment, "success")}<br/>
                    {generateBadgeRow("Tags", option.tags, "danger")}<br/>
                    <span style={{"margin": "0"}}>
                        Pace/Mets/One RM: <strong>{option.base_pace}</strong>/<strong>{option.base_mets}</strong>/<strong>{option.one_rm}</strong>
                    </span><br/>
                    <span style={{"margin": "0"}}>
                        Exercise Level: <strong>{option.exercise_level}</strong>
                    </span><br/>
                </div>
            </div>
        </components.Option>
    );
};


export const ExerciseModel = (constants, loadExercises) => {
    let objCache = {}
    return {
        name: {
            type: 'input',
            validationRules: 'required',
            value: '',
        },
        preview: {
            type: 'input',
            validationRules: ['required', {'regex': "^https://mobile.appscdn.io/.*"}],
            value: '',
        },
        media_url: {
            type: 'input',
            validationRules: ['required', {'regex': "^https://mobile.appscdn.io/.*"}],
            value: '',
        },
        body_parts: buildSelector(constToSelectOptions(constants, 'body_part'), 'multiSelect'),
        equipment: buildSelector(constToSelectOptions(constants, 'equipment'), 'multiSelect'),
        exercise_level: {
            ...buildSelector(constToSelectOptions(constants, 'exercise_level'), 'select'),
            validationRules: '',
        },
        exercise_type: {
            ...buildSelector(constToSelectOptions(constants, 'exercise_type'), 'select'),
            validationRules: '',
        },
        equipment_group: {
            ...buildSelector(constToSelectOptions(constants, 'equipment_groups'), 'select'),
            validationRules: '',
        },
        workout_block: {
            ...buildSelector(constToSelectOptions(constants, 'workout_block'), 'multiSelect'),
            validationRules: '',
        },
        age_group: {
            ...buildSelector(constToSelectOptions(constants, 'age_group'), 'multiSelect'),
            validationRules: '',
        },
        bmi_level: {
            ...buildSelector(constToSelectOptions(constants, 'bmi_level'), 'multiSelect'),
            validationRules: '',
        },
        workout_type: {
            ...buildSelector(constToSelectOptions(constants, 'workout_type'), 'multiSelect'),
            validationRules: '',
            value: []
        },
        wb_fitness_level: {
            ...buildSelector(constToSelectOptions(constants, 'exercise_level'), 'multiSelect'),
            validationRules: '',
        },
        is_published: {
            ...yesNoSelector(),
            validationRules: '',
        },
        symmetrical_exercise_id: {
            type: 'select',
            validationRules: '',
            onChangeEvent: 'handleSelect',
            value: '',
            requestNormalizer: (option) => {
                if (option === null || option === undefined){
                        return null
                    }
                return option.id
            },
            responseNormalizer: (option) => {
                let exercise_id = option
                if (objCache[exercise_id] !== undefined) {
                    return objCache[exercise_id]
                }
                let queryParams = {
                "per_page": "100",
                "page":"1"
                }
                if (exercise_id !== null) {
                    queryParams['ids'] = JSON.stringify([parseFloat(exercise_id)])
                }

                return API.get('admin', `/admin/exercises/`, {
                    'queryStringParameters': queryParams
                })
                    .then(data => {
                        let exercises = data.items.reduce((obj, item) => {
                            objCache[item.id] = item;
                            return obj;
                        }, {});
                        if (exercise_id !==null && objCache[exercise_id] !== undefined){
                            return objCache[exercise_id]
                        }
                        else{
                            return exercises[0];
                        }
                    }).catch(error => {
                        console.error(error)
                        return {}
                    })
            },
            selectProps: {
                components: {Option},
                getOptionLabel: option => {
                    if (option === null){
                        return null
                    }
                    return `[${option['id']}] ${option['name']}`
                },
                getOptionValue: option => {
                    if (option === null){
                        return null
                    }
                    return option['id']
                },
                pageSize: 10,
                minMenuHeight: 300,
                maxMenuHeight: 1000,
                loadOptions: loadExercises,
            },
            md: 5
        },
        base_pace: {
            inputType: 'number',
            validationRules: 'required',
            value: '',
            requestNormalizer: (data) => {
                return parseFloat(data);
            }
        },
        base_mets: {
            inputType: 'number',
            validationRules: 'required',
            value: '',
            requestNormalizer: (data) => {
                return parseFloat(data);
            }
        },
    };
}

export const ExerciseFilter = (constants) => {
    let model = {};

    for (const field_name of ExerciseKeys) {
        model[field_name] = buildSelector(constToSelectOptions(constants, field_name))
    }
    return {
        ids: {
            type: 'input',
            value: '',
            placeholder: 'Ids',
            requestNormalizer: (data) => {
                return JSON.stringify(Array.from(data.matchAll(/\d+/g), m => parseInt(m[0])));
            },
        },
        name: {
            type: 'input',
            value: '',
        },
        workout_type: {
            ...buildSelector(constToSelectOptions(constants, 'workout_type')),
            validationRules: '',
        },
        wb_fitness_level: {
            ...buildSelector(constToSelectOptions(constants, 'exercise_level')),
            validationRules: '',
        },
        ...model

    };
};