export const WorkoutTranslationModel = (workout_id, language_id, name, audio_url, instruction) => {
    return {
        workout_id: {
            value: workout_id,
            hidden: true
        },
        language: {
            value: language_id,
            hidden: true
        },
        name: {
            type: 'input',
            validationRules: 'required',
            value: name,
        },
        audio_url: {
            type: 'input',
            validationRules: 'required',
            value: audio_url,
        },
        instruction: {
            type: 'input',
            validationRules: 'required',
            value: instruction,
        }
    };
}
