import {buildSelector, constToSelectOptions} from "../utils/Utils";

export const ContentDownloadModel = constants => {
    return {
        content_table: buildSelector(
            constToSelectOptions(constants, 'content_table'),
            'select',
            (data) => {
                return data.value;
            },
        ),
        emails: {
            type: 'input',
            value: '',
            validationRules: 'required',
            placeholder: 'Emails separated by comma',
            requestNormalizer: (data) => {
                return JSON.stringify(data.split(','));
            }
        }
    }
};