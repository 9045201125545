import ReviewersPasswordAdd from "./ReviewersPasswordAdd";

export default class ReviewersPasswordEdit extends ReviewersPasswordAdd {
    constructor(props) {
        super(props);
        this.skipPrepopulation = true
    }
    getEntity = () => {
        return {
            email:{type: 'input', validationRules: 'required',value: ''},
        }
    }
}