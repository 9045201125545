import BaseTranslationComponent from "../../Generic/BaseTranslationComponent";

export default class WorkoutTranslation extends BaseTranslationComponent {
    constructor(props) {
        super(props);
        this.name = "Workout Translations"
        this.addPath = "/admin/translations/workouts/add";
        this.editPath = "/admin/translations/workouts/edit";
        this.removeUrl = "/admin/translations/workouts";
        this.listApiUrl = "/admin/translations/workouts";
        this.instructionUrl="https://welltech.atlassian.net/wiki/spaces/MYC/pages/4008542804/Workout+Translation";
    }
}
