import React, {Component} from "react";
import {UserTokenFilter} from "../../models/User";
import List from "../../components/List/List";
import {ApiConstantsContext} from "../../variables/ApiConstantsContext";
import {NavLink} from "react-router-dom";
import Button from "react-bootstrap/lib/Button";
import defaults from "../../defaults";
import API from "@aws-amplify/api";

let g = defaults.userGroups

class ReviewersPassword extends Component {
    static contextType = ApiConstantsContext;

    constructor(props){
        super(props);
        this.addPath = "/admin/reviewers/add";
        this.editPath = "/admin/reviewers/edit";
        this.state = {
            key: 0
        }

    }
    setReviewersStatus = (event, reviewer_id, status) => {
        let button = event.target;
        button.disable = true;
        let url = `/admin/reviewers/${reviewer_id}/status`

        API.put('admin', url, {'body': {"is_published": status}})
            .then(data=> {
                this.props.handleClick(`Successfully set status to ${status}`, "success", "tr");
                this.setState({key: Math.random()});
            }).catch(error=>{
                this.props.handleClick("Failed to set status: " + JSON.stringify(error.response.data), "error", "tr");
        }).finally(() => {button.disable = false;})
    };

    restoreReviewerPassword = (event, reviewer_id, email) => {
        let button = event.target;
        button.disable = true;
        let url = `/admin/reviewers/${reviewer_id}/restore-password`

        API.post('admin', url, {'body': {"email": email}})
            .then(data=> {
                this.props.handleClick(`Successfully restored password`, "success", "tr");
                this.setState({key: Math.random()});
            }).catch(error=>{
                this.props.handleClick("Failed to restore password: " + JSON.stringify(error.response.data), "error", "tr");
        }).finally(() => {button.disable = false;})
    }
    render(){
        let c = this.context.get_const;
        return (
            <List
                key={this.state.key}
                name="ReviewersPassword"
                actions={[
                    (list, data) => {
                        return <NavLink
                            to={{
                                pathname: list.props.editPath + '/' + data.id,
                                state: {
                                    training_goal: data,
                                }
                            }}
                            className="btn btn-primary btn-fill"
                            >Edit</NavLink>
                    },
                    (list, data) => {
                        if (data.status === "soft_deleted") {
                            return undefined;
                        }
                        return <Button
                            allowedGroups={[g.admin]}
                            className="btn btn-danger btn-fill"
                            onClick={(e) => list.handleRemove(data.id, e)}>
                            Delete
                        </Button>
                    },
                    (list, data) => {
                        if (data.status === "published") {

                            return <Button
                                className="btn btn-warning btn-fill"
                                onClick={(e) => this.setReviewersStatus(e, data.id, false)}
                            >
                                Unpublish
                            </Button>
                        } else {
                            return <Button
                                className="btn btn-info btn-fill"
                                onClick={(e) => this.setReviewersStatus(e, data.id, true)}
                            >
                                Publish
                            </Button>
                        }
                    },
                    (list, data) => {
                        return <Button
                                className="btn btn-dark btn-fill"
                                onClick={(e) => this.restoreReviewerPassword(e, data.id, data.email)}
                            >
                                Restore password
                            </Button>
                    }
                ]}
                addPath={this.addPath}
                editPath={this.editPath}
                listApiUrl="/admin/reviewers"
                removeUrl="/admin/reviewers"
                model={[
                    {col: 'Id', row: 'id'},
                    {col: 'Email', row: 'email'},
                    {
                        col: 'Status',
                        row: 'status',
                        normalizer: (data) => {
                            let colors = {
                                "published": "green",
                                "not_published": "orange"
                            }
                             return <p
                                style={{color: colors[data.status] || "black"}}>{c("workout_status", data.status)}</p>
                        }
                    }
                ]}
                handleClick={this.props.handleClick}
                filterObject={UserTokenFilter}
                userHasPermission={this.props.userHasPermission}
            >
            </List>
        )

    }
}

export default ReviewersPassword;