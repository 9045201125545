import {buildSelector, constToSelectOptions, yesNoSelector} from "../utils/Utils";

export const GenderWorkoutTypeModel = constants => {
    return {
        workout_type: buildSelector(constToSelectOptions(constants, 'workout_type'), 'select'),
        preview: {
            type: 'input',
            validationRules: ['required', {'regex': "^https://mobile.appscdn.io/.*"}],
            value: '',
        },
        preview_compact: {
            type: 'input',
            validationRules: ['required', {'regex': "^https://mobile.appscdn.io/.*"}],
            value: '',
        },
        web_preview_compact:{
            type: 'input',
            validationRules: [{'regex': "^https://mobile.appscdn.io/.*"}],
            value: '',
        },
         media_url: {
            type: 'input',
            validationRules: [{'regex': "^https://mobile.appscdn.io/.*"}],
            value: '',
        },
        gender: buildSelector(constToSelectOptions(constants, 'workout_gender'), 'select'),
        levels: {
            inputType: 'levels',
            validationRules: 'required',
            requestNormalizer: (data) => {
                return parseInt(data);
            },
        },
        is_premium: yesNoSelector(),
        used_in_builder: yesNoSelector(),
        default_days: {
            ...buildSelector(constToSelectOptions(constants, 'default_days'), 'multiSelect'),
            validationRules: 'required',
            value: [],
            md: 12,
        },
        min_workouts_per_week: {
            inputType: 'min_workouts_per_week',
            validationRules: 'range: [0, 6]',
            requestNormalizer: (data) => {
                return parseInt(data);
            },
        },
        max_workouts_per_week: {
            inputType: 'max_workouts_per_week',
            validationRules: 'range: [0, 6]',
            requestNormalizer: (data) => {
                return parseInt(data);
            },
        },
        body_part_groups: {
            ...buildSelector(constToSelectOptions(constants, 'body_part_group'), 'multiSelect'),
            value: [],
            md: 12,
            validationRules: ''
        },
        equipment_groups: {
            ...buildSelector(constToSelectOptions(constants, 'equipment_groups'), 'multiSelect'),
            value: [],
            md: 12,
            validationRules: ''
        },
        position: {
            validationRules: 'required',
            requestNormalizer: (data) => {
                return parseInt(data);
            }
        },
    };
}

export const GenderWorkoutTypeFilter = constants => {
    return {
        gender: buildSelector(
            constToSelectOptions(constants, 'workout_gender'),
            'multiSelect',
            (data) => {
                return JSON.stringify(data.map(a => a.value));
            }
        ),
        is_published: yesNoSelector(),
        is_premium: yesNoSelector(),

    }
};
