import BaseTranslationComponent from "../../Generic/BaseTranslationComponent";

export default class EquipmentGroupTranslation extends BaseTranslationComponent {
    constructor(props) {
        super(props);
        this.name = "Equipment Group Translations"
        this.addPath = "/admin/translations/equipment-groups/add";
        this.editPath = "/admin/translations/equipment-groups/edit";
        this.removeUrl = "/admin/translations/equipment-groups";
        this.listApiUrl = "/admin/translations/equipment-groups";
        this.instructionUrl="https://welltech.atlassian.net/wiki/spaces/MYC/pages/4008509465/Equipment+Group+Translation";
    }
}