import BaseAddEditComponent from "../Generic/BaseAddEditComponent";

export default class ReviewersPasswordAdd extends BaseAddEditComponent{
    constructor(props) {
        super(props);
        this.name="ReviewersPassword"
        this.url="/admin/reviewers"
        this.redirectUrl = "/admin/reviewers"
    }
    getEntity = () => {
        return {
            email:{type: 'input', validationRules: 'required',value: ''},
        }
    }
}