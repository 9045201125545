import BaseAddEditComponent from "../Generic/BaseAddEditComponent";
import {TrainingGoals} from "../../models/TrainingGoals";
import {API} from "aws-amplify";
import debounce from "debounce-promise";

export default class TrainingGoalsAdd extends BaseAddEditComponent{
    constructor(props) {
        super(props);
        this.name="TrainingGoals"
        this.url="/admin/training-goals"
        this.debouncedLoadOptions = debounce(this.loadWorkouts, 2000);
        if (props.location.state !== undefined) {
            this.gender = props.location.state.training_goal.gender
        }
    }

    detectSearchParameter = input => {
        input = input.toLowerCase()
        if (!isNaN(input)) {
            return ["ids", JSON.stringify([parseFloat(input)])]
        }
        return ["name", input]
    }

    loadWorkouts = input => {
        let queryParams = {
            "per_page": "100",
            "workout_types": JSON.stringify(["training_program"])
        }
        if (this.gender) {
            queryParams.genders = JSON.stringify([this.gender])
        }
        if (input.length > 0) {
            let [searchParam, searchValue] = this.detectSearchParameter(input)
            queryParams[searchParam] = searchValue
        }
        return API.get("admin", "/admin/workouts", {
            "queryStringParameters": queryParams
        })
            .then(data => {
                let workouts = data.items.reduce((obj, item) => {
                    obj[item.id] = item;
                    return obj;
                }, {});
                return Object.values(workouts);
            }).catch(error => {
                console.error(error)
                return []
            })
    }

    getEntity = () => {
        return TrainingGoals(
            this.context.constants, inputValue => this.debouncedLoadOptions(inputValue)
        )
    }
}