import React from "react";
import BaseAddEditComponent from "../Generic/BaseAddEditComponent";
import {ContentDownloadModel} from "../../models/ContentDownload";

class ContentDownload extends BaseAddEditComponent {
    constructor(props) {
        super(props);
        this.name = "Content Download"
        this.url = "/admin/content-download"
        this.redirectUrl = "/admin/content-download"
    }
    getEntity = () => {
        return ContentDownloadModel(
            this.context.constants,
        );
    }
    render() {
        return React.cloneElement(
            super.render(),
            {md: 12}
        )
    }
}

export default ContentDownload;