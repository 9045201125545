import BaseAddEditComponent from "../Generic/BaseAddEditComponent";
import {ApiConstantsContext} from "../../variables/ApiConstantsContext";
import {GenderEquipmentsModel} from "../../models/GenderEquipments";

export default class GenderEquipmentAdd extends BaseAddEditComponent {
    static contextType = ApiConstantsContext;
    constructor(props) {
        super(props);
        this.name = "GenderEquipments"
        this.url = "/admin/gender-equipments"

    }
    getEntity = () => {
        return GenderEquipmentsModel(this.context.constants);
    }
}
