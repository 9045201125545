import React, {Component} from "react";
import List from "../../components/List/List";
import {ApiConstantsContext} from "../../variables/ApiConstantsContext";
import {constList} from "../../utils/Utils";
import {AudioFilter} from "../../models/UniversalAudio";

export default class UniversalAudio extends Component {
    static contextType = ApiConstantsContext;

    constructor(props) {
        super(props);
        this.addPath = '/admin/universal-audio/add';
        this.editPath = '/admin/universal-audio/edit';

    }

    render() {
        let constants = this.context.constants;
        let c = this.context.get_const;
        return (
            <List
                name="Universal-Audio"
                addPath={this.addPath}
                editPath={this.editPath}
                removeUrl="/admin/universal-audio"
                listApiUrl='/admin/universal-audio'
                model={[
                    {col: 'Id', row: 'id'},

                    {
                        col: 'Audio-type',
                        row: 'Audio-type',
                        normalizer: (data) => {
                            return c('audio_type', data.audio_type)
                        }
                    },

                    {col: 'Text', row: 'text'},


                    {
                        col: 'Language',
                        row: 'language',
                        normalizer: (data) => {
                            return c('audio_language', data.audio_language)
                        }
                    },

                    {
                        col: 'Workout Type',
                        row: 'workout_type',
                        normalizer: (data) => {
                            return constList(c, data, 'workout_type');
                        }
                    },

                ]}
                onRemove={this.onRemove}
                handleClick={this.props.handleClick}
                filterObject={AudioFilter(constants)}
                userHasPermission={this.props.userHasPermission}
            >
            </List>

        );
    }
}